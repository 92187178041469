<template>
  <section class="pb-2">
    <b-card body-class="px-0">
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Auto in vendita </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Auto in vendita</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <b-row class="px-2 mt-2 align-items-center">
        <b-col md="10">
          <b-row>
            <b-col md="3">
              <b-form-group label="Marca">
                <v-select
                  :options="brand"
                  label="nome_casamadre"
                  :reduce="(brand) => brand.nome_casamadre"
                  @input="getCarModelByBrand"
                  v-model="selected_brand"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Modello">
                <v-select
                  :options="models"
                  label="modello"
                  :reduce="(models) => models.modello"
                  v-model="selected_model"
                />
              </b-form-group>
            </b-col>
            
          </b-row>
        </b-col>

        <b-col md="2">
          <b-button class="mt-1" block variant="primary-light" @click="getAutoOnSale(currentPage)">
            Cerca Auto
          </b-button>
        </b-col>
      </b-row>

      <hr />

      <b-row class="px-2 my-2">
        <b-col md="12">
          <h1 class="text-primary-light text-center">La nostra selezione</h1>
        </b-col>
      </b-row>

      <b-row class="px-2">
        <b-col
          v-for="car in cars.data"
          :key="car.id_auto"
          sm="12"
          md="6"
          lg="4"
          xl="3"
        >
          <b-card
            class="cursor-pointer"
            img
            @click="$router.push('/cars_on_sale/' + car.id_auto)"
          >
            <b-img
              class="mb-2 rounded rounded-b-0"
              fluid
              :src="
                car.foto1_ex
                  ? car.foto1_ex
                  : 'https://via.placeholder.com/350x250'
              "
            >
            </b-img>

            <div class="d-flex justify-content-between align-items-center">
              <p class="h3 m-0">€<b>20.000</b></p>

              <div
                class="d-flex justify-content-center align-items-center rounded-circle shadow shadow-md p-1"
                @click.stop="null"
              >
                <feather-icon
                  icon="StarIcon"
                  class="text-primary"
                  size="30"
                  @click="addToFavorites(car.id_auto)"
                />
              </div>
            </div>

            <div>
              <p class="mb-0 text-uppercase text-primary-light">
                <b>{{ car.marca }} - {{ car.modello }}</b>
              </p>
              <p>{{ car.versione }}</p>
            </div>

            <hr />

            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <b-img
                  class="mr-1"
                  src="@/assets/icons/engine.svg"
                  height="15px"
                />
                <span>{{ car.potenza }} CV</span>
              </div>

              <div class="d-flex align-items-center">
                <b-img
                  class="mr-1"
                  src="@/assets/icons/fuel.svg"
                  height="20px"
                />
                <span>{{ car.alimentazione }}</span>
              </div>

              <div class="d-flex align-items-center">
                <b-img
                  class="mr-1"
                  src="@/assets/icons/calendar.svg"
                  height="20px"
                />
                <span>{{ car.immatricolazione_anno }}</span>
              </div>
            </div>

            <hr />

            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <b-img
                  class="mr-1"
                  src="@/assets/icons/road.svg"
                  height="15px"
                />
                <span>{{ car.chilometraggio }} km</span>
              </div>

              <div class="d-flex align-items-center">
                <b-img
                  class="mr-1"
                  src="@/assets/icons/cambio.svg"
                  height="20px"
                />
                <span>{{ car.tipo_cambio }}</span>
              </div>
            </div>

            <hr />

            <b-button
              block
              variant="primary"
              @click.stop="addToCart(car)"
              >Aggiungi al carrello</b-button
            >
          </b-card>
        </b-col>
      </b-row>

      <b-row class="px-2">
        <b-col md="12">
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="cars.count"
            aria-controls="carList"
            align="right"
            size="sm"
            class="my-0"
            @change="getAutoOnSale"
          />
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BFormGroup,
  BImg,
  BPagination,
} from "bootstrap-vue";

import { Requests } from "@/api";
import { mapActions } from "vuex";

import vSelect from "vue-select";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BFormGroup,
    BImg,
    vSelect,
    BPagination,
  },

  data: () => ({
    cars: [],
    brand: [],
    models: [],
    selected_brand: "",
    selected_model: "",

    //Helpers

    loading: false,
    perPage: 8,
    currentPage: 1,
  }),

  created() {
    this.getAutoOnSale();
    this.getCarBrand();
  },

  methods: {
    ...mapActions({ addItem: "addItem" }),

    async getCarBrand() {
      try {
        const response = await Requests.getCarBrand();
        this.brand = response;
      } catch (err) {
        console.debug(err);
      }
    },

    async getCarModelByBrand() {
      let data = new FormData();

      data.append("casamadre", this.selected_brand);

      try {
        const response = await Requests.getCarModelByBrand(data);
        this.models = response;
      } catch (err) {
        console.debug(err);
      }
    },

    async getAutoOnSale(page) {
      this.loading = true;

      let request_data = new FormData();

      request_data.append("type", "reseller");
      request_data.append("offset", (page - 1) * this.perPage);
      request_data.append("marca", this.selected_brand);
      request_data.append("modello", this.selected_model);
      request_data.append("limit", this.perPage);

      try {
        const response = await Requests.getAutoOnSale(request_data);
        this.cars = response;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },

    async addToCart(car) {
      this.loading = true;

      let request_data = new FormData();

      request_data.append("id_auto", car.id_auto);
      this.addItem();

      try {
        await Requests.addToCart(request_data);
      } catch (err) {
        console.debug(err);
      }

      this.loading = true;
    },

    async addToFavorites(id_auto) {
      this.loading = true;

      let request_data = new FormData();

      request_data.append("id_auto", id_auto);

      try {
        await Requests.addToFavorites(request_data);
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
